import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FeedDiv,StyledPopup,PopupWrapper} from './styles';
import SingleFeed from '../SocialFeedsV2/singleFeedForComments';
import { ImageUrl } from '../../utils/constants';
import { CongratulationsEmojiIcon } from '../../utils/icons';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isEmpty';
// import connect from 'react-redux/es/connect/connect';
import LazyImage from '../common/LazyImage/LazyImage';
import {connect} from 'react-redux';
import { SliderContainer, RecognitionImage, RecognitionBirthdayAnniversary, UserInitials, RecogntionContainer, WrapImage } from "../SocialFeedsV2/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Waiting from '../Waiting';
import { withTranslation } from 'react-i18next';
class ViewCommentModal extends Component{

  onClosePopup = ()=>{
    this.props.onClose();
    this.props.history.push({pathname:`/social`,state:null});
  }

  hidePopup = (id, value)=>{
    this.props.changeEditDeletePopup(id, value);
    this.props.onClose();
  }

  containsDefaultPng = (str) => {
    const defaultPngFound = str?.includes("default.png");
    return defaultPngFound;
  }

  getInitials = (inputString) =>{
    const words = inputString?.split(' ');
    const initials = words?.map((word) => word[0]?.toUpperCase());
    return initials?.join('');
  }

  render(){
    const { showModal, feed, comments, inspirationPost, user } = this.props;
    const singleFeedData= inspirationPost? inspirationPost:feed;
    window.console.log("testing data",feed, singleFeedData);
    if (isEmpty(singleFeedData)) {
      return <Waiting />
    }
    let socialFeed = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      draggable: true,
      adaptiveHeight: true,
      nextArrow: <img src={ImageUrl+"/social-feed/rightArrow.svg"} style={{width:"24px", height:"24px"}}/>,
      prevArrow: <img src={ImageUrl+"/social-feed/leftArrow.svg"} style={{width:"24px", height:"24px"}}/>,
      responsive: [
        {
          breakpoint: 1252,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };
    return(
      <StyledPopup show={showModal} width={singleFeedData && isNull(singleFeedData.post_images) ?"540px !important":"1200px !important"}>
        <PopupWrapper width={singleFeedData && isNull(singleFeedData.post_images) ?"100%":"33.3%"}>
          {singleFeedData && isNull(singleFeedData.post_images)?null:<div className='imageContainer' style={{display:"flex", alignItems:"center"}}>
            {singleFeedData?.mentioned_post.length>1?
              <SliderContainer
                display="flex"
                content="center"
                shadow="0px 8px 25px 0px #0D427040"
                maxHeight
                style={{margin:"auto"}}
              >
                <Slider {...socialFeed}>
                  {singleFeedData && singleFeedData.post_images && singleFeedData?.mentioned_post.map((data,index)=>(
                    data.fullname?(singleFeedData.core_value=="Happy Birthday"||singleFeedData.core_value=="Work Anniversary")?
                      <RecognitionBirthdayAnniversary margin image={singleFeedData.core_value=="Work Anniversary"?"social-feed/anniversaryCover.svg":"social-feed/birthdayCover.svg"}  height="568px">
                        <div className='mainContainer'>
                          <div className='wish'>{singleFeedData.core_value=="Work Anniversary"?"Happy Work Anniversary!":"Happy Birthday!"}</div>
                          <div className='wish-name'>{data.fullname}</div>
                          <div className='image'>
                            <div className='imageWidth'>
                              {data?.profile_image && !this.containsDefaultPng(data?.profile_image)?
                                <img onError={this.fallBack} src={`${ImageUrl}/${data?.profile_image}`}/>:
                                <UserInitials color={feed?.recognition_color_value}>{this.getInitials(data?.fullname)}</UserInitials>}
                            </div>
                          </div>
                          {singleFeedData.core_value=="Work Anniversary"?
                            <div className='message'>Your dedication and hard work have been an inspiration to us <br/> all. Here’s to many more years of success together!</div>:
                            <div className='message'>May this year bring you continued success and fulfillment in <br/>both your personal and professional life.</div>}
                        </div>
                      </RecognitionBirthdayAnniversary>:
                      <RecognitionImage margin image={singleFeedData.bg_image} key={index}  height="100%">
                        <div className='mainContainer'>
                          <div className='image'>
                            <div className='imageWidth'>
                              {data?.profile_image && !this.containsDefaultPng(data?.profile_image)?
                                <img src={`${ImageUrl}/${data?.profile_image}`}/>:
                                <UserInitials color={feed?.recognition_color_value}>{this.getInitials(data?.fullname)}</UserInitials>}
                            </div>
                            <RecogntionContainer border={feed?.recognition_color_value}>
                              <div className='recogntionvalue'>
                                <div className='image'>
                                  <img src={`${ImageUrl}/${singleFeedData?.recognition_image}`}/>
                                </div>
                                <div className='name'>
                                  {singleFeedData?.core_value}
                                </div>
                              </div>
                            </RecogntionContainer>
                            <div className='recognition'>
                              <span className='textRecognition'>{"Congratulations to"}</span>&nbsp;
                              <span className='boldRecognition'>{data?.fullname}</span>&nbsp;
                              {CongratulationsEmojiIcon()}
                            </div>
                          </div>
                        </div>
                      </RecognitionImage>:
                      <WrapImage noMargin key={index} >
                        <img src={`${ImageUrl}/${data.profile_image}`} alt={data.body || 'placeholder'}  style={{maxHeight:"600px"}}/>
                      </WrapImage>))}
                </Slider>
              </SliderContainer>: 
              singleFeedData.image  ? 
                <WrapImage noMargin >
                  <LazyImage src={`${ImageUrl}/${singleFeedData.image}`} alt={singleFeedData.body || 'placeholder'} />
                </WrapImage>: 
                singleFeedData.core_value?
                  (singleFeedData.core_value=="Happy Birthday"||singleFeedData.core_value=="Work Anniversary")?
                    <RecognitionBirthdayAnniversary background margin image={singleFeedData.core_value=="Work Anniversary"?"social-feed/anniversaryCover.svg":"social-feed/birthdayCover.svg"}  height="600px">
                      <div className='mainContainer'>
                        <div className='wish'>{singleFeedData.core_value=="Work Anniversary"?"Happy Work Anniversary!":"Happy Birthday!"}</div>
                        <div className='wish-name'>{singleFeedData?.mentioned_users[0]?.fullname}</div>
                        <div className='image'>
                          <div className='imageWidth'>
                            {singleFeedData?.mentioned_users[0]?.profile_image && !this.containsDefaultPng(singleFeedData?.mentioned_users[0]?.profile_image)?<img src={`${ImageUrl}/${singleFeedData?.mentioned_users[0]?.profile_image}`}/>:
                              <UserInitials color={feed?.recognition_color_value}>{this.getInitials(singleFeedData?.mentioned_users[0]?.fullname)}</UserInitials>}
                          </div>
                        </div>
                        {singleFeedData.core_value=="Work Anniversary"?
                          <div className='message'>Your dedication and hard work have been an inspiration to us <br/> all. Here’s to many more years of success together!</div>:
                          <div className='message'>May this year bring you continued success and fulfillment in <br/>both your personal and professional life.</div>}
                      </div>
                    </RecognitionBirthdayAnniversary>
                    :<RecognitionImage margin image={singleFeedData.bg_image}  height="535px" background="100% 535px">
                      <div className='mainContainer'>
                        <div className='image'>
                          <div className='imageWidth'>
                            {singleFeedData?.mentioned_users[0]?.profile_image && !this.containsDefaultPng(singleFeedData?.mentioned_users[0]?.profile_image)?<img src={`${ImageUrl}/${singleFeedData?.mentioned_users[0]?.profile_image}`}/>:
                              <UserInitials color={feed?.recognition_color_value}>{this.getInitials(singleFeedData?.mentioned_users[0]?.fullname)}</UserInitials>}
                          </div>
                          <RecogntionContainer border={feed?.recognition_color_value}>
                            <div className='recogntionvalue'>
                              <div className='image'>
                                <img src={`${ImageUrl}/${singleFeedData?.recognition_image}`}/>
                              </div>
                              <div className='name'>
                                {singleFeedData?.core_value}
                              </div>
                            </div>
                          </RecogntionContainer>
                          <div className='recognition'>
                            <span className='textRecognition'>{"Congratulations to"}</span>&nbsp;
                            <span className='boldRecognition'>{singleFeedData?.mentioned_users[0]?.fullname}</span>&nbsp;
                            {CongratulationsEmojiIcon()}
                          </div>
                        </div>
                      </div>
                    </RecognitionImage>:null}
          </div>}
          <div className='commentsContainer'>
            <FeedDiv widthValue={singleFeedData.image? true : true} radius={singleFeedData && isNull(singleFeedData.post_images)}>
              <SingleFeed
                feed={singleFeedData}
                description={singleFeedData.description}
                taggedUsers={singleFeedData.shoutout_user_name}
                getCommentsForPost={this.props.getCommentsForPost}
                changeEditDeletePopup={this.hidePopup}
                editAndDelete = {this.props.editAndDelete}
                openEditAndDelete={this.props.openEditAndDelete}
                profileImage={this.props.profileImage}
                updationList="response"
                commentCount={singleFeedData.comment_count}
                history={this.props.history}
                enableTrigger={this.props.enableTrigger}
                // key={`feed-${index}-${feed.id}`}
                onChange={this.props.onChange}
                handleFeed={this.props.handleFeed}
                handleModal = {this.props.handleModal}
                feedNumber={this.props.feedNumber}
                data = {this.props.data}
                exemplifiesValues = {this.props.exemplifiesValues}
                showRecognition = {this.props.showRecognition}
                value = {this.props.value}
                id = {this.props.id}
                image = {this.props.image}
                feedNo = {this.props.feedNo}
                updateFeedsLike={this.props.updateFeedsLike}
                storedFeeds={this.props.storedFeeds}
                editPost={this.props.editPost}
                socialCallBack={this.props.socialCallBack}
                handlePhotoPostModal={this.props.handlePhotoPostModal}
                hideCommentText = {false}
                hideValuesInCommentsModel={true}
                comments={comments}
                postReply={this.props.postReply}
                replySuccess={this.props.replySuccess}
                commentId={singleFeedData.id}
                closeCommentPoup={this.props.onClose}
                isCommentModal={true}
                onClosePopup={this.onClosePopup}
                user={user}
              />
            </FeedDiv>
          </div>
        </PopupWrapper>
      </StyledPopup>
    )
  }
}

ViewCommentModal.propTypes =  {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  feed: PropTypes.object.isRequired,
  description: PropTypes.string,
  taggedUsers: PropTypes.string,
  getCommentsForPost: PropTypes.func.isRequired,
  changeEditDeletePopup: PropTypes.func.isRequired,
  editAndDelete: PropTypes.bool.isRequired,
  openEditAndDelete: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  updationList: PropTypes.string,
  commentCount: PropTypes.number,
  history: PropTypes.object,
  enableTrigger: PropTypes.bool,
  onChange: PropTypes.func,
  handleFeed: PropTypes.func,
  handleModal: PropTypes.func.isRequired,
  feedNumber : PropTypes.number,
  data: PropTypes.array.isRequired,
  exemplifiesValues : PropTypes.array,
  showRecognition : PropTypes.func,
  value : PropTypes.string,
  id : PropTypes.number,
  image : PropTypes.string,
  feedNo : PropTypes.number,
  updateFeedsLike: PropTypes.func,
  editPost: PropTypes.func,
  storedFeeds: PropTypes.array,
  socialCallBack: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  postReplyOnComment: PropTypes.func.isRequired,
  comments: PropTypes.array,
  postReply: PropTypes.func,
  replySuccess: PropTypes.string,
  inspirationPost: PropTypes.array,
  t: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  inspirationPost: state.social.inspirationPost
});

export default connect(mapStateToProps, null)((withTranslation())(ViewCommentModal));